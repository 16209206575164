
/*.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {*/
    /*background: rgb(52, 53, 89) !important;*/
/*}*/

/*个人信息栏 去掉右边界蓝边*/
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}
.ant-table-column-title {
    color: white;
}
.ant-table-body {
    color: white;
}

.ant-table-thead > tr > th {
  background: transparent !important;
}


.ant-table {
    font-size: 16px !important;
    line-height: 1 !important;
}
