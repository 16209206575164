
html, body, #root {
  height: 100%;
}


.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-body {
  background: white;
}

.self-menu-item {
  color: black;
  opacity: 0.7;
  cursor: pointer;
}

.self-menu-item:hover {
  color: black;
  opacity: 1;
}

/*去掉导航栏下的蓝条*/
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: none;
}

/*menu下拉框样式设置*/
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  color: white;
  background: transparent;
  text-align: center;
  min-width: 90px;
  padding: 0;
  border-right: 0;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}

.ant-menu-horizontal {
  border-bottom: none;
}

/*下拉框底层背景色*/
.ant-menu-submenu-popup {
  background: black;
}

/*模态框样式*/
.ant-modal-content {
  background: white;
  /*background: -webkit-linear-gradient(top, #DDDDDD, #E0E7EE);*/
}


input.input-with-myborder {
  outline-style: none ;
  outline-width: 0;
  border: 1px #B0B0B0 solid;
  text-shadow: none ;
  -webkit-appearance: none ;
  -webkit-user-select: text ;
  outline-color: black ;
  box-shadow: none;
  padding: 5px 20px;
  height: 100%;
  color: black;
  border-radius: 20px !important;
  background: #f2f2f2 ;
  font-size: 14px;
}

input.input-with-myborder:hover {
  background-color: #e8e8e8 ;
}

input.input-with-myborder:focus {
  background-color: #d8d8d8 ;
}

/*button.ant-btn{*/
  /*border-color: #4ECDFF;*/
  /*width:100%;*/
  /*background:#4ECDFF;*/
  /*color:white;*/
  /*font-size:15px;*/
  /*border-radius:10px*/
/*}*/

/*button.ant-btn:focus{*/
  /*color:white;*/
  /*background: #53C1FF;*/
  /*border-color: #53C1FF;*/
/*}*/

/*button.ant-btn:hover{*/
  /*color:white;*/
  /*background: #53C1FF;*/
  /*border-color: #53C1FF;*/
/*}*/

/*button.ant-btn:disabled:hover{*/
  /*color:white;*/
  /*background: #17A9FF;*/
  /*border-color: #17A9FF;*/
/*}*/

/*button.ant-btn:disabled{*/
  /*color:white;*/
  /*background: #17A9FF;*/
  /*border-color: #17A9FF;*/
/*}*/

button.button-with-myborder {
  outline-style: none ;
  outline-width: 0;
  border: 1px gray solid;
  text-shadow: none ;
  -webkit-appearance: none ;
  -webkit-user-select: text ;
  outline-color: black ;
  box-shadow: none;
  padding: 3.5px;
  height: 21px;
  font-size: 12px;
  font-weight: bold;
}

.transform-103:hover {
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
}

.transform-105:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.transform-110:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}


.scroll-list::-webkit-scrollbar {/*滚动条整体样式*/
    width: 10px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
    scrollbar-arrow-color:red;
}
.scroll-list::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
    scrollbar-arrow-color:red;
}
.scroll-list::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: rgba(0,0,0,0.1);
}

.banner-img-radius-10 {
  border-radius: 10px;
}

.banner-img-radius-20 {
  border-radius: 20px;
}

.select-highlight {
  opacity: 0.85;
}

.select-highlight:hover {
  opacity: 1;
}
