.article-container p {
    margin-bottom: 3px;
    color: black;
    font-size: 14px;
    font-weight: 400;
}
.article-container img {
    max-width: 100%;
    min-width: 20%;
    height: auto !important;
}
