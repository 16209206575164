 .loading {
  margin: 10em auto 0 auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  text-indent: -9999em;
  -webkit-animation: loading 1.1s infinite ease;
  animation: loading 2s infinite ease;
}
 /*70 189 85*/
@-webkit-keyframes loading {
  0%,
  100% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.1), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.5), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.7);
  }
  12% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.7), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.1), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.5);
  }
  25% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.5), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.7), 2em 0em 0 0em rgba(70, 189, 85, 0.1), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.5), 2em 0em 0 0em rgba(70, 189, 85, 0.7), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  50% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.5), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.7), 0em 2em 0 0em rgba(70, 189, 85, 0.1), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  62% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.5), 0em 2em 0 0em rgba(70, 189, 85, 0.7), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.1), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  75% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.5), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.7), -2.08em 0em 0 0em rgba(70, 189, 85, 0.1), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.5), -2.08em 0em 0 0em rgba(70, 189, 85, 0.7), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.1);
  }
}
@keyframes loading {
  0%,
  100% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.1), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.5), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.7);
  }
  12% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.7), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.1), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.5);
  }
  25% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.5), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.7), 2em 0em 0 0em rgba(70, 189, 85, 0.1), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.5), 2em 0em 0 0em rgba(70, 189, 85, 0.7), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  50% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.5), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.7), 0em 2em 0 0em rgba(70, 189, 85, 0.1), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.2), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  62% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.5), 0em 2em 0 0em rgba(70, 189, 85, 0.7), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.1), -2.08em 0em 0 0em rgba(70, 189, 85, 0.2), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  75% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.5), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.7), -2.08em 0em 0 0em rgba(70, 189, 85, 0.1), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.08em 0em 0em rgba(70, 189, 85, 0.2), 1.44em -1.44em 0 0em rgba(70, 189, 85, 0.2), 2em 0em 0 0em rgba(70, 189, 85, 0.2), 1.4em 1.4em 0 0em rgba(70, 189, 85, 0.2), 0em 2em 0 0em rgba(70, 189, 85, 0.2), -1.44em 1.44em 0 0em rgba(70, 189, 85, 0.5), -2.08em 0em 0 0em rgba(70, 189, 85, 0.7), -1.44em -1.44em 0 0em rgba(70, 189, 85, 0.1);
  }
}
