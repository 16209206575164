.ant-menu-item {
    margin: -1px 30px 0 !important;
    height: 60px !important;
    padding-top: 12px !important;
    background: #fdfdfd !important;
}
.profile-sub-menu {
    float: right;
    margin-top: 12px !important;
    margin-right: 60px !important;
}
#header-menu .ant-menu-root {
    height: 60px;
    width: 100%;
    box-shadow: 0 1px 5px 0 rgb(238 238 238);
    z-index: 100;
    top: 0;
    position: fixed;
}
.login-button {
    float: right;
    margin-top: 12px !important;
    margin-right: 60px !important;
}
.login-button.ant-btn-primary {
    color: black !important;
    background: #fdfdfd !important;
    border-color: #777e80 !important;
}
.login-button.ant-btn-primary:hover {
    color: #1890ff !important;
    border-color: #1890ff !important;
}

.ant-menu-item-only-child {
    height: 40px !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
}

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 50px ;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
    overflow-y: scroll;
    overflow-x: hidden;
}

.self-link:hover {
    color: deepskyblue;
}

.ant-table-wrapper {
    background: white;
}

.ant-table-pagination.ant-pagination {
    padding-right: 20px;
}
