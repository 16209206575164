/*@import url('http://fonts.googleapis.com/css?family=Noto+Serif:400,400italic,700|Open+Sans:400,600,700');*/
@import url('font-awesome.css');
@import url('animate.css');

body {
	font-family:'Open Sans', Arial, sans-serif;
	font-weight:300;
	line-height:1.6em;
	color:#656565;
	font-size: 14px;
}

a:active {
	outline:0;
}

.clear {
	clear:both;
}

h1,h2{
	font-weight: 600;
	line-height:1.1em;
	color:#333;
	margin-bottom: 20px;
	text-transform: uppercase;
}
h3, h4, h5, h6 {
	line-height:1.1em;
	color:#333;
	margin-bottom: 20px;
}
input, button, select, textarea {
    border-radius: 0px !important;
}
.timetable{background: #ff9818;padding: 5px 23px;}
.timetable hr{
	color:#fff;
	padding-top: 29px;
	margin-bottom: 20px !important;
}
.timetable dl{
	color:#fff;
}
.timetable dt{
	color:#fff;
}
.timetable dd{
	color:#fff;
}
#wrapper{
	width:100%;
	margin:0;
	padding:0;
}
ul.withArrow{

}
ul.withArrow li{

}
ul.withArrow li span{

margin-right: 10px;

font-weight: 700;

color: #46bd55;

border: 1px solid;

padding: 4px 8px;

font-size: 11px;

border-radius: 50%;

height: 20px;

width: 20px;
}
.widget {
    color: #858585;
}
.row,.row-fluid {
	margin-bottom:30px;
}

.row .row,.row-fluid .row-fluid{
	margin-bottom:30px;
}

.row.nomargin,.row-fluid.nomargin {
	margin-bottom:0;
}
.about-image img {
    width: 100%;
}
img.img-polaroid {
	margin:0 0 20px 0;
}
.img-box {
	max-width:100%;
}
.navbar-default .navbar-toggle {
    border-color: #fff;
    margin-top: 17px;
}
.service-v1 h3{

font-size: 16px;

/*color: #46bd55;*/

font-weight: bold;

margin-bottom: 10px;
}
.section-padding{
	padding:30px;
	background: white;
}
/*  Header
==================================== */
.topbar{
	background-color: #46bd55;
	padding: 5px 0;
	color:#fff;
}
.topbar .container .row {
    margin: 0;
	padding:0;
}
.topbar .container .row .col-md-12 {
	padding:0;
}
.topbar p{
	margin:0;
	display:inline-block;
	font-size: 14px;
	color: #ffffff;
}
.topbar p > i{
	margin-right:5px;
	color: #ffffff;
}
.topbar p:last-child{
	text-align:right;
}
header .navbar {
    margin-bottom: 0;
}

.navbar-default {
    border: none;
}

.navbar-brand {
    color: #222;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: -1px;
    margin-top: 14px;
    padding: 0 0 0 15px;
}
.navbar-default .navbar-brand{
color: #1891EC;
}

header .navbar-collapse  ul.navbar-nav {
    float: right;
    margin-right: 0;
}
header .navbar {min-height: 70px;padding: 18px 0;}
.home-page header .navbar-default{
    background: #ffffff;
    /* position: absolute; */
    width: 100%;
}

header .nav li a:hover,
header .nav li a:focus,
header .nav li.active a,
header .nav li.active a:hover,
header .nav li a.dropdown-toggle:hover,
header .nav li a.dropdown-toggle:focus,
header .nav li.active ul.dropdown-menu li a:hover,
header .nav li.active ul.dropdown-menu li.active a{
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}


header .navbar-default .navbar-nav > .open > a,
header .navbar-default .navbar-nav > .open > a:hover,
header .navbar-default .navbar-nav > .open > a:focus {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}


header .navbar {
    /* min-height: 62px; */
    padding: 0;
}

header .navbar-nav > li  {
    padding-bottom: 12px;
    padding-top: 12px;
    padding: 0 !important;
}

header  .navbar-nav > li > a {
    /* padding-bottom: 6px; */
    /* padding-top: 5px; */
    margin-left: 2px;
    line-height: 19px;
    font-weight: 700;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}


.dropdown-menu li a:hover {
    color: #fff !important;
    background: #46bd55 !important;
}

header .nav .caret {
    border-bottom-color: #ffffff;
    border-top-color: #ffffff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: #fff;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color:  #fff;
}


.dropdown-menu  {
    box-shadow: none;
    border-radius: 0;
	border: none;
}

.dropdown-menu li:last-child  {
	padding-bottom: 0 !important;
	margin-bottom: 0;
}

header .nav li .dropdown-menu  {
   padding: 0;
   background: #ffffff;
   box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.22);
}

header .nav li .dropdown-menu li a {
   line-height: 28px;
   padding: 3px 12px;
}

/* --- menu --- */
.flex-caption p {
    line-height: 22px;
    font-weight: 300;
    color: #d2d2d2;
}
header .navigation {
	float:right;
}

header ul.nav li {
	border:none;
	margin:0;
}

header ul.nav li a {
	font-size: 20px;
	border:none;
	font-weight: 600;
	text-transform:uppercase;
	padding: 25px 15px;
}

header ul.nav li ul li a {
	font-size:18px;
	border:none;
	font-weight:500;
	text-transform:uppercase;
}


.navbar .nav > li > a {
  color: #212121;
  text-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0) !important;
}

.navbar .nav a:hover {
	background:none;
	color: #ff9818;
}

.navbar .nav > .active > a,.navbar .nav > .active > a:hover {
	background:none;
}

.navbar .nav > .active > a:active,.navbar .nav > .active > a:focus {
	background:none;
	outline:0;
	font-weight:700;
}

.navbar .nav li .dropdown-menu {
	z-index:2000;
}

header ul.nav li ul {
	margin-top:1px;
}
header ul.nav li ul li ul {
	margin:1px 0 0 1px;
}
.dropdown-menu .dropdown i {
	position:absolute;
	right:0;
	margin-top:3px;
	padding-left:20px;
}

.navbar .nav > li > .dropdown-menu:before {
  display: inline-block;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-bottom-color: none;
  content:none;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {color: #46bd55;border-radius: 0;}
.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
  color: #46bd55;
  background-color: transparent;
}

ul.nav li.dropdown a {
	z-index:1000;
	display:block;
}

 select.selectmenu {
	display:none;
}
.pageTitle{
color: #fff;
margin: 30px 0 3px;
display: inline-block;
}

#banner{
	width: 100%;
	background:#000;
	position:relative;
	margin:0;
	padding:0;
}

#main-slider:before {
	content: '';
	width: 100%;
	height: 100%;
	background: rgba(43, 150, 204, 0.1);
	z-index: 1;
	position: absolute;
}
.flex-direction-nav a{
display:none;
}
.flexslider {
	padding:0;
	background: #fff;
	position: relative;
	zoom: 1;
}
.flex-direction-nav .flex-prev{
left:0px;
}
.flex-direction-nav .flex-next{
right:0px;
}
.flex-caption {zoom: 1;bottom: 198px;background-color: transparent;color: #fff;margin: 0;padding: 2px 25px 0px 30px;/* position: absolute; */left: 0;text-align: center;margin: 0 auto;right: 0px;display: inline-block;bottom: 90px;width: 50%;right: 0 !important;border-radius: 15px 15px 0 0;/* display: none; */}
.flex-caption h3 {color: #fff;letter-spacing: 0px;margin-bottom: 20px;/* text-transform: uppercase; */font-size: 46px;font-weight: 700;text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.44);}
.flex-caption p {margin: 12px 0 18px;font-size: 19px;font-weight: 400;}
.skill-home{margin-bottom:50px;margin-top: -102px;position: relative;z-index: 9;}
.c1{
border: #ed5441 1px solid;
background:#ed5441;
}
.c2{
border: #24c4db 1px solid;
background:#24c4db;
}
.c3{
border: #1891EC 1px solid;
background:#1891EC;
}
.c4{
border: #609cec 1px solid;
background:#609cec;
}
.skill-home .icons {padding: 20px;width: 94px;/* height: 94px; */color: #fff;font-size: 42px;font-size: 38px;text-align: center;-ms-border-radius: 50%;-moz-border-radius: 50%;-webkit-border-radius: 50%;border-radius: 0;display: inline-table;color: #46bd55;background: transparent;border-radius: 0;background: #ffffff;border: none;width: 100%;border: 1px solid #ffffff;box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.28);border-bottom: 2px solid #46bd55;}
.skill-home h2 {
padding-top: 20px;
font-size: 36px;
font-weight: 700;
}
.testimonial-solid {
padding: 50px 0 60px 0;
margin: 0 0 0 0;
background: #FFFFFF;
text-align: center;
}
.testi-icon-area {
text-align: center;
position: absolute;
top: -84px;
margin: 0 auto;
width: 100%;
}
.testi-icon-area .quote {
padding: 15px 0 0 0;
margin: 0 0 0 0;
background: #ffffff;
text-align: center;
color: #1891EC;
display: inline-table;
width: 70px;
height: 70px;
-ms-border-radius: 50%;
-moz-border-radius: 50%;
-webkit-border-radius: 50%;
border-radius: 50%;
font-size: 42px;
border: 1px solid #1891EC;
display: none;
}

.testi-icon-area .carousel-inner {
margin: 20px 0;
}
.carousel-indicators {
bottom: -30px;
}
.text-center img {
margin: auto;
}
.aboutUs{
padding:40px 0;
background: #F2F2F2;
}
img.img-center {
margin: 0 auto;
display: block;
max-width: 100%;
}
.home-about{
	padding: 60px 0 0px;
}
.home-about hr{
	padding:0;
	margin:0;
}
.home-about .info-box{

}
/* Testimonial
----------------------------------*/
.testimonial-area {
padding: 0 0 0 0;
margin:0;
background: url(../img/about.jpg) fixed center center;
background-size: cover;
-webkit-background-size: cover;
-moz-background-size: cover;
-ms-background-size: cover;
}
.testimonial-solid p {
color: #000000;
font-size: 16px;
line-height: 30px;
font-style: italic;
}
section.hero-text {
	background: #F4783B;
	padding:50px 0 50px 0;
	color: #fff;
}
section.hero-text h1{
	color: #fff;
}

/* Clients
------------------------------------ */
#clients {
  padding: 60px 0;
  }
  #clients .client .img {
    height: 76px;
    width: 138px;
    cursor: pointer;
    -webkit-transition: box-shadow .1s linear;
    -moz-transition: box-shadow .1s linear;
    transition: box-shadow .1s linear; }
    #clients .client .img:hover {
      cursor: pointer;
      /*box-shadow: 0px 0px 2px 0px rgb(155, 155, 155);*/
      border-radius: 8px; }
  #clients .client .client1 {
    background: url("../img/client1.png") 0 -75px; }
    #clients .client .client1:hover {
      background-position: 1px 0px; }
  #clients .client .client2 {
    background: url("../img/client2.png") 0 -75px; }
    #clients .client .client2:hover {
      background-position: -1px 0px; }
  #clients .client .client3 {
    background: url("../img/client3.png") 0 -76px; }
    #clients .client .client3:hover {
      background-position: 0px 0px; }


/* Content
==================================== */

#content {
	position:relative;
	background: #ffffff;
	padding:50px 0 0px 0;
}

.cta-text {
	text-align: center;
	margin-top:10px;
}


.big-cta .cta {
	margin-top:10px;
}

.box {
	width: 100%;
}
.box-gray  {
	background: #f8f8f8;
	padding: 20px 20px 30px;
}
.box-gray  h4,.box-gray  i {
	margin-bottom: 20px;
}
.box-bottom {
	padding: 20px 0;
	text-align: center;
}
.box-bottom a {
	color: #fff;
	font-weight: 700;
}
.box-bottom a:hover {
	color: #eee;
	text-decoration: none;
}
.help-block ul li {
    list-style: none;
    color: red;
    padding: 0;
    margin: 0;
}

/* Bottom
==================================== */

#bottom {
	background:#fcfcfc;
	padding:50px 0 0;

}
/* twitter */
#twitter-wrapper {
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
#twitter em {
    font-style: normal;
    font-size: 14px;
}

#twitter em.twitterTime a {
	font-weight:600;
}

#twitter ul {
    padding: 0;
	list-style:none;
}
#twitter ul li {
    font-size: 20px;
    line-height: 1.6em;
    font-weight: 300;
    margin-bottom: 20px;
    position: relative;
    word-break: break-word;
}
.noTopMrgn{
	margin-top:0px;
	padding-top:0px;
	background: #fff;
}
.coloured{
	color: #46bd55;
}
/* page headline
==================================== */

#inner-headline{
	margin: 0;padding: 0;position:relative;
	background: url("../img/slides/small.png");
	background-repeat:no-repeat;
}

section#inner-headline:after {
    content: '';
    background: rgba(0, 0, 0, 0.55);
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
	color: white !important;
}

#inner-headline h2.pageTitle{
	color: #46bd55;
	padding: 25px 0;
	display:block;
	text-align: center;
	position: relative;
	z-index: 3;
}

/* --- breadcrumbs --- */
#inner-headline ul.breadcrumb {
	margin:40px 0;
	float:left;
}

#inner-headline ul.breadcrumb li {
	margin-bottom:0;
	padding-bottom:0;
}
#inner-headline ul.breadcrumb li {
	font-size:13px;
	color:#fff;
}

#inner-headline ul.breadcrumb li i{
	color:#dedede;
}

#inner-headline ul.breadcrumb li a {
	color:#fff;
}

ul.breadcrumb li a:hover {
	text-decoration:none;
}

/* Forms
============================= */

/* --- contact form  ---- */
form#contactform input[type="text"] {
  width: 100%;
  border: 1px solid #f5f5f5;
  min-height: 40px;
  padding-left:20px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;

}

form#contactform textarea {
border: 1px solid #f5f5f5;
  width: 100%;
  padding-left:20px;
  padding-top:10px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;

}

form#contactform .validation {
	font-size:11px;
}

#sendmessage {
	border:1px solid #e6e6e6;
	background:#f6f6f6;
	display:none;
	text-align:center;
	padding:15px 12px 15px 65px;
	margin:10px 0;
	font-weight:600;
	margin-bottom:30px;

}

#sendmessage.show,.show  {
	display:block;
}

form#commentform input[type="text"] {
  width: 100%;
  min-height: 40px;
  padding-left:20px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
	-webkit-border-radius: 2px 2px 2px 2px;
		-moz-border-radius: 2px 2px 2px 2px;
			border-radius: 2px 2px 2px 2px;

}

form#commentform textarea {
  width: 100%;
  padding-left:20px;
  padding-top:10px;
  font-size:13px;
  padding-right:20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
	-webkit-border-radius: 2px 2px 2px 2px;
		-moz-border-radius: 2px 2px 2px 2px;
			border-radius: 2px 2px 2px 2px;
}


/* --- search form --- */
.search{
	float:right;
	margin:35px 0 0;
	padding-bottom:0;
}

#inner-headline form.input-append {
	margin:0;
	padding:0;
}

.about-text h3 {
	font-size: 22px;
	padding-bottom: 20px;
}

.about-text .btn {
	margin-top: 20px;
	//font-size: 15px;
	//text-align: justify;
}

.about-image img {
}

.about-text ul {
	margin-top: 30px;
	padding: 0;
}

.about-text li {
	margin-bottom: 10px;
	font-weight: bold;
	list-style: none;
}


.about-text-2 img {
	border: 5px solid #ddd;
	margin-bottom: 20px;
}

.about-text-2 p {
	margin-bottom: 20px;
}

#call-to-action {
	background: url(../img/about.jpg) no-repeat;
	background-attachment: fixed;
	background-position: top center;
	padding: 100px 0;
	color: #fff;
}

.call-to-action h3 {
	font-size: 40px;
	letter-spacing: 15px;
	color: #fff;
	padding-bottom: 30px;
}

.call-to-action p {
	color: #999;
	padding-bottom: 20px;
}

.call-to-action .btn-primary {
	background-color: transparent;
	border-color: #999;
}

.call-to-action .btn-primary:hover {
	background-color: #151515;
	color: #999;
	border-color: #151515;
}

#call-to-action-2 {
	background-color: #2ab975;
	padding: 50px 0;
}
#call-to-action-2 h3 {
	color: #eaeaea;
	font-size: 28px;
	font-weight: 400;
}
#call-to-action-2 p {
	color: #eaeaea;
	font-size: 15px;
}
#call-to-action-2 .btn-primary {
	border-color: #FFFFFF;
	background: transparent;
	margin-top: 50px;
	color: #fff;
}
section.section-padding.gray-bg {
    background: #f0f0f0;
    padding: 30px;
    color: #484848;
}

  #accordion-alt3 .panel-heading h4 {
font-size: 14px;
line-height: 28px;
}
.panel .panel-heading h4 {
font-weight: 400;
}
.panel-title {
margin-top: 0;
margin-bottom: 0;
font-size: 15px;
color: inherit;
}
.panel-group .panel {
margin-bottom: 0;
border-radius: 2px;
}
.panel {margin-bottom: 18px;background-color: #FFFFFF;border: 1px solid #f3f3f3;border-radius: 2px;-webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.05);box-shadow: 0 1px 1px rgba(0,0,0,0.05);}
#accordion-alt3 .panel-heading h4 a i {font-size: 17px;line-height: 18px;width: 18px;height: 18px;margin-right: 8px;color: #A0A0A0;text-align: center;border-radius: 50%;margin-left: 6px;font-weight: bold;}
.progress.pb-sm {
height: 6px!important;
}
.progress {
box-shadow: inset 0 0 2px rgba(0,0,0,.1);
}
.progress {
overflow: hidden;
height: 18px;
margin-bottom: 18px;
background-color: #f5f5f5;
border-radius: 2px;
-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}
.progress .progress-bar.progress-bar-red {
background: #ed5441;
}
.progress .progress-bar.progress-bar-green {
background: #51d466;
}
.progress .progress-bar.progress-bar-lblue {
background: #32c8de;
}
/* --- Projects detail --- */
.top-wrapper {
	margin-bottom:20px;
}
.info-blocks {
margin-bottom: 15px;
}
.info-blocks i.icon-info-blocks {float: left;color: #46bd55;font-size: 30px;min-width: 50px;margin-top: 7px;text-align: center;background: #ffffff;width: 72px;height: 72px;padding-top: 21px;border: 1px solid #46bd55;}
.info-blocks .info-blocks-in {
padding: 0 10px;
overflow: hidden;
}
.info-blocks .info-blocks-in h3 {color: #555;font-size: 18px;line-height: 28px;margin:0px;}
.info-blocks .info-blocks-in p {font-size: 14px;}

blockquote {
	font-size:16px;
	font-weight:400;
	font-family:'Noto Serif', serif;
	font-style:italic;
	padding-left:0;
	color:#a2a2a2;
	line-height:1.6em;
	border:none;
}

blockquote cite 							{ display:block; font-size:12px; color:#666; margin-top:10px; }
blockquote cite:before 					{ content:"\2014 \0020"; }
blockquote cite a,
blockquote cite a:visited,
blockquote cite a:visited 				{ color:#555; }

/* --- pullquotes --- */

.pullquote-left {
	display:block;
	color:#a2a2a2;
	font-family:'Noto Serif', serif;
	font-size:14px;
	line-height:1.6em;
	padding-left:20px;
}

.pullquote-right {
	display:block;
	color:#a2a2a2;
	font-family:'Noto Serif', serif;
	font-size:14px;
	line-height:1.6em;
	padding-right:20px;
}

/* --- button --- */
.btn{text-align: center;color: #FFFFFF;padding: 12px 25px;border-radius: 0;background: #46bd55;border-color: transparent;}
.btn-theme {
	color: #fff;
	background: transparent;
	border: 1px solid #fff;
	padding: 12px 30px;
	font-weight: bold;
}
.btn-theme:hover {
	color: #eee;
}

/* --- list style --- */

ul.general {
	list-style:none;
	margin-left:0;
}

ul.link-list{
	margin:0;
	padding:0;
	list-style:none;
}

ul.link-list li{
	margin:0;
	padding:2px 0 2px 0;
	list-style:none;
}
footer{background: #191919;}
footer ul.link-list li a{
	color: #8C8C8C;
}
footer ul.link-list li a:hover {
	color: #46bd55;
}
/* --- Heading style --- */

h4.heading {
	font-weight:700;
}

.heading { margin-bottom: 30px; }

.heading {
	position: relative;

}


.widgetheading {
	width:100%;
	padding:0;
	font-weight: bold;
}

#bottom .widgetheading {
	position: relative;
	border-bottom: #e6e6e6 1px solid;
	padding-bottom: 9px;
}

aside .widgetheading {
	position: relative;
	border-bottom: #e9e9e9 1px solid;
	padding-bottom: 9px;
}

footer .widgetheading {
	position: relative;
}

footer .widget .social-network {
	position:relative;
}

.team-member h4{
  text-align: center;
  margin-bottom: 5px;
}
.team-member .deg{
text-align:center;
display:block;
}
#bottom .widget .widgetheading span, aside .widget .widgetheading span, footer .widget .widgetheading span {
	position: absolute;
	width: 60px;
	height: 1px;
	bottom: -1px;
	right:0;

}
.panel-title>a {
    color: inherit;
    text-decoration: none;
    font-weight: 600;
}
.box-area{padding: 15px;background: #f7f7f7;/* margin-top: -21px; */border: none;color: #616161;font-size: 14px;box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.39);border-bottom: 1px solid #e5e5e5;}
.box-area h3{font-size: 16px;font-weight: 700;color: #000;text-transform: uppercase;}
/* --- Map --- */
.map{
	position:relative;
	margin-top:-50px;
	margin-bottom:40px;
}

.map iframe{
	width:100%;
	height:450px;
	border:none;
}

.map-grid iframe{
	width:100%;
	height:350px;
	border:none;
	margin:0 0 -5px 0;
	padding:0;
}


ul.team-detail{
	margin:-10px 0 0 0;
	padding:0;
	list-style:none;
}

ul.team-detail li{
	border-bottom:1px dotted #e9e9e9;
	margin:0 0 15px 0;
	padding:0 0 15px 0;
	list-style:none;
}

ul.team-detail li label {
	font-size:13px;
}

ul.team-detail li h4, ul.team-detail li label{
	margin-bottom:0;
}

ul.team-detail li ul.social-network {
	border:none;
	margin:0;
	padding:0;
}

ul.team-detail li ul.social-network li {
	border:none;
	margin:0;
}
ul.team-detail li ul.social-network li i {
	margin:0;
}


.pricing-title{
	background:#fff;
	text-align:center;
	padding:10px 0 10px 0;
}

.pricing-title h3{
	font-weight:600;
	margin-bottom:0;
}

.pricing-offer{
	background: #fcfcfc;
	text-align: center;
	padding:40px 0 40px 0;
	font-size:18px;
	border-top:1px solid #e6e6e6;
	border-bottom:1px solid #e6e6e6;
}

.pricing-box.activeItem .pricing-offer{
	color:#fff;
}

.pricing-offer strong{
	font-size:78px;
	line-height:89px;
}

.pricing-offer sup{
	font-size:28px;
}

.pricing-container{
	background: #fff;
	text-align:center;
	font-size:14px;
}

.pricing-container strong{
color:#353535;
}

.pricing-container ul{
	list-style:none;
	padding:0;
	margin:0;
}

.pricing-container ul li{
	border-bottom: 1px solid #CFCFCF;
list-style: none;
padding: 15px 0 15px 0;
margin: 0 0 0 0;
color: #222;
}

.pricing-action{
	margin:0;
	background: #fcfcfc;
	text-align:center;
	padding:20px 0 30px 0;
}

.pricing-wrapp{
	margin:0 auto;
	width:100%;
	background:#fd0000;
}
 .pricing-box-item {border: 1px solid #e6e6e6;background:#fcfcfc;position:relative;margin:0 0 20px 0;padding:0;-webkit-box-shadow: 0 2px 0 rgba(0,0,0,0.03);-moz-box-shadow: 0 2px 0 rgba(0,0,0,0.03);box-shadow: 0 2px 0 rgba(0,0,0,0.03);-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;overflow: hidden;}

.pricing-box-item .pricing-heading {
	background: #ffffff;
	text-align: center;
	padding: 50px 0;
	display:block;
}
.pricing-box-item.activeItem .pricing-heading {
	background: #ffffff;
	text-align: center;
	border-bottom:none;
	display:block;
	color: #46bd55;
}
.pricing-box-item.activeItem .pricing-heading h3 {

color: #46bd55;
}

.pricing-box-item .pricing-heading h3 strong {
	font-size:28px;
	font-weight:700;
	letter-spacing:-1px;
	/* color: #46bd55; */
}
.pricing-box-item .pricing-heading h3 {
	font-size:32px;
	font-weight:300;
	letter-spacing:-1px;
}

.pricing-box-item .pricing-terms {
	text-align: center;
	background: #fff;
	display: block;
	overflow: hidden;
	padding: 11px 0 5px;
	/* border-top: 2px solid #BBBBBB; */
	border-bottom: 2px dotted #CDCDCD;
}

.pricing-box-item .pricing-terms  h6 {
	color: #46bd55;
	font-size: 22px;
	background: #ededed;
	padding: 25px;
	margin: 0 0 -5px;
	border-top: 3px solid #46bd55;
}

.pricing-box-item .icon .price-circled {
    margin: 10px 10px 10px 0;
    display: inline-block !important;
    text-align: center !important;
    color: #fff;
    width: 68px;
    height: 68px;
	padding:12px;
    font-size: 16px;
	font-weight:700;
    line-height: 68px;
    text-shadow:none;
    cursor: pointer;
    background-color: #888;
    border-radius: 64px;
    -moz-border-radius: 64px;
    -webkit-border-radius: 64px;
}

.pricing-box-item  .pricing-action{
	margin:0;
	text-align:center;
	padding:30px 0 30px 0;
}
.pricing-action .btn{
	background: #46bd55;
	color: #fff;
}
/* ===== Widgets ===== */

/* --- flickr --- */
.widget .flickr_badge {
	width:100%;
}
.widget .flickr_badge img { margin: 0 9px 20px 0; }

footer .widget .flickr_badge {
    width: 100%;
}
footer .widget .flickr_badge img {
    margin: 0 9px 20px 0;
}

.flickr_badge img {
    width: 50px;
    height: 50px;
    float: left;
	margin: 0 9px 20px 0;
}

/* --- Recent post widget --- */

.recent-post{
	margin:20px 0 0 0;
	padding:0;
	line-height:18px;
}

.recent-post h5 a:hover {
	text-decoration:none;
}

.recent-post .text h5 a {
	color:#353535;
}


footer{
	padding:50px 0 0 0;
	color: #052321;
}

footer a {
	color: #bbb;
}

footer a:hover {
	color:#eee;
}

footer h1, footer h2, footer h3, footer h4, footer h5, footer h6{
	color: #46bd55;
}

footer address {
	line-height:1.6em;
	color: #797979;
}

footer h5 a:hover, footer a:hover {
	text-decoration:none;
}

ul.social-network {
	list-style:none;
	margin:0;
}

ul.social-network li {
	display:inline;
	margin: 0 5px;
	border: 1px solid #2D2D2D;
	padding: 5px 0 0;
	width: 32px;
	display: inline-block;
	text-align: center;
	height: 32px;
	vertical-align: baseline;
}

#sub-footer{
	text-shadow:none;
	color:#f5f5f5;
	padding:0;
	padding-top:30px;
	margin:20px 0 0 0;
	background: #191919;
}

#sub-footer p{
	margin:0;
	padding:0;
	color: #c5c5c5;
}

#sub-footer span{
	color: #828282;
}

.copyright {
	text-align:left;
	font-size:12px;
	color: #b3b3b3;
}

#sub-footer ul.social-network {
	float:right;
}



/* scroll to top */
.scrollup{
    position:fixed;
    width:32px;
    height:32px;
    bottom:0px;
    right:20px;
    background: #46bd55;
}

a.scrollup {
	outline:0;
	text-align: center;
}

a.scrollup:hover,a.scrollup:active,a.scrollup:focus {
	opacity:1;
	text-decoration:none;
}
a.scrollup i {
	margin-top: 10px;
	color: #fff;
}
a.scrollup i:hover {
	text-decoration:none;
}




.absolute{
	position:absolute;
}

.relative{
	position:relative;
}

.aligncenter{
	text-align:center;
}

.aligncenter span{
	margin-left:0;
}

.floatright {
	float:right;
}

.floatleft {
	float:left;
}

.floatnone {
	float:none;
}

.aligncenter {
	text-align:center;
}

img.pull-left, .align-left{
	float:left;
	margin:0 15px 15px 0;
}

.widget img.pull-left {
	float:left;
	margin:0 15px 15px 0;
}

img.pull-right, .align-right {
	float:right;
	margin:0 0 15px 15px;
}

article img.pull-left, article .align-left{
	float:left;
	margin:5px 15px 15px 0;
}

article img.pull-right, article .align-right{
	float:right;
	margin:5px 0 15px 15px;
}

.clear-marginbot{
	margin-bottom:0;
}

.marginbot10{
	margin-bottom:10px;
}
.marginbot20{
	margin-bottom:20px;
}
.marginbot30{
	margin-bottom:30px;
}
.marginbot40{
	margin-bottom:40px;
}

.clear-margintop{
	margin-top:0;
}

.margintop10{
	margin-top:10px;
}

.margintop20{
	margin-top:20px;
}

.margintop30{
	margin-top:30px;
}

.margintop40{
	margin-top:40px;
}
.flex-control-paging li a {
  width: 16px;
  height: 16px;
  border-radius: 0;
  background: #ffffff;
  text-indent: 50px;
  overflow: hidden;
}
.btn:hover, .btn:focus {
  color: #FFF;
  text-decoration: none;
  background: #35ac44;
  border-color: transparent;
}
/*  Media queries
============================= */


@media screen and (min-width: 768px) and (max-width: 979px) {


	a.detail{
		background:none;
		width:100%;
	}



	footer .widget form  input#appendedInputButton {
		  display: block;
		  width: 91%;
		  -webkit-border-radius: 4px 4px 4px 4px;
			 -moz-border-radius: 4px 4px 4px 4px;
				  border-radius: 4px 4px 4px 4px;
	}

	footer .widget form  .input-append .btn {
		  display: block;
		  width: 100%;
		  padding-right: 0;
		  padding-left: 0;
		  -webkit-box-sizing: border-box;
			 -moz-box-sizing: border-box;
				  box-sizing: border-box;
				  margin-top:10px;
	}

	ul.related-folio li{
		width:156px;
		margin:0 20px 0 0;
	}
}

@media screen and (max-width: 767px) {

.navbar-default .navbar-collapse{border-color: none;background: #46bd55;}
.navbar-default .navbar-toggle .icon-bar {background-color: #46bd55;border-radius: 0;}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
background-color: rgba(221, 221, 221, 0);
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
border-color: rgba(255, 255, 255, 0.58);
margin-top: 15px;
}
header ul.nav li a{
padding:10px 10px;
}
header ul.nav li a:hover {
    color: #fff !important;
}
.flex-caption h3{
	font-size:28px;
}
  body {
    padding-right: 0;
    padding-left: 0;
  }
	.navbar-brand {
		margin-top: 14px;
		border-bottom: none;
	}
	.navbar-header {
		/* margin-top: 20px; */
		border-bottom: none;
	}

	.navbar-nav {
		border-top: none;
		float: none;
		width: 100%;
	}
.navbar .nav > .active > a, .navbar .nav > .active > a:hover {background: none;font-weight: 700;color: #f3f3f3;padding: 10px 10px;}
	header .navbar-nav > li {
padding-bottom: 2px;
padding-top: 3px;
}
	header .nav li .dropdown-menu  {
		margin-top: 0;
	}

	.dropdown-menu {
	  position: absolute;
	  top: 0;
	  left: 40px;
	  z-index: 1000;
	  display: none;
	  float: left;
	  min-width: 160px;
	  padding: 5px 0;
	  margin: 2px 0 0;
	  font-size: 14px;
	  list-style: none;
	  background-color: #fff;
	  background-clip: padding-box;
	  border: 1px solid #f5f5f5;
	  border: 1px solid rgba(0, 0, 0, .15);
	  border-radius: 0;
	  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
			  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	}



	li.active  {
		border: none;
		overflow: hidden;
		/* background: #f3f3f3; */
}


	.box {
		border-bottom:1px solid #e9e9e9;
		padding-bottom:20px;
	}

	.flexslider .slide-caption {
		width: 90%;
		padding: 2%;
		position: absolute;
		left: 0;
		bottom: -40px;
	}


	#inner-headline .breadcrumb {
		float:left;
		clear:both;
		width:100%;
	}

	.breadcrumb > li {
		font-size:13px;
	}


	ul.Projects li article a i.icon-48{
		width:20px;
		height:20px;
		font-size:16px;
		line-height:20px;
	}


	.left-sidebar{
		border-right:none;
		padding:0 0 0 0;
		border-bottom: 1px dotted #e6e6e6;
		padding-bottom:10px;
		margin-bottom:40px;
	}

	.right-sidebar{
		margin-top:30px;
		border-left:none;
		padding:0 0 0 0;
	}


	footer .col-lg-1, footer .col-lg-2, footer .col-lg-3, footer .col-lg-4, footer .col-lg-5, footer .col-lg-6,
	footer .col-lg-7, footer .col-lg-8, footer .col-lg-9, footer .col-lg-10, footer .col-lg-11, footer .col-lg-12{
		margin-bottom:20px;
	}

	#sub-footer ul.social-network {
		float:left;
	}



  [class*="span"] {
		margin-bottom:20px;
  }

}

@media screen and (max-width: 480px) {


	.bottom-article a.pull-right {
		float:left;
		margin-top:20px;
	}


	.search{
		float:left;
	}

	.flexslider .flex-caption {
		display:none;
	}


	.cta-text {
		margin:0 auto;
		text-align:center;
	}

	ul.Projects li article a i{
		width:20px;
		height:20px;
		font-size:14px;
	}


}


